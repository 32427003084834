import React,{useEffect} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';

const Nav = () => {
  let curLoc = useLocation();
  let curPath = "Home";
  if (curLoc.pathname !== "/") {
    curPath = curLoc.pathname;
    curPath = curPath[1].toUpperCase() + curPath.substring(2);
  }

  return (
    <div className="w-full navbar bg-base-300">
      <div className="flex-none lg:hidden">
          <label htmlFor="sidebar" aria-label="open sidebar">
              <MenuIcon />
          </label>
      </div>
      <div className="flex-1 px-2 mx-2">Website Portfolio - {curPath}</div>
      <div className="flex-3 hidden lg:block">
          <ul className="menu menu-horizontal">
          {/* Navbar menu content here */}          
          </ul>
      </div>
      {/**
       * 
       * Theme Toggle
       */}
      <div className='flex-none'>
      <label className="cursor-pointer grid place-items-center">
          <input type="checkbox" value="synthwave" className="toggle theme-controller bg-base-content row-start-1 col-start-1 col-span-2"/>
          <svg className="col-start-1 row-start-1 stroke-base-100 fill-base-100" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="5"/><path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"/></svg>
          <svg className="col-start-2 row-start-1 stroke-base-100 fill-base-100" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
      </label>
      </div>
    </div>
  )
}

export default Nav;